import React from 'react';
import { SuggestionContainer, SuggestionButton } from './style';

const SuggestionMessages = ({ suggestions = [], onSuggestionClick, isPopup }) => {
  if (suggestions.length === 0) return null;

  return (
    <SuggestionContainer isPopup={isPopup}>
      {suggestions.map((suggestion, index) => (
        <SuggestionButton
          key={index}
          onClick={() => onSuggestionClick(suggestion)}
          isPopup={isPopup}
        >
          {suggestion}
        </SuggestionButton>
      ))}
    </SuggestionContainer>
  );
};

export default SuggestionMessages;