import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { io } from "socket.io-client";
import {
  SidebarContainer,
  Header,
  LogoIcon,
  Nav,
  NavItem,
  TeamStorage,
  Caption,
  ItemImg,
  ItemText,
  ToggleButton,
  DividerDiv,
  UserDiv,
  UserText,
  More,
  Coming,
  Popup,
  PopupItem,
  ExpandButton,
  Counter
} from "./Style";
import { ComingSoon } from "../GridItems/Style";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../Components/Alerts/SnackbarAlert";
import Logo from "../../assets/Logo.svg";
import FolderIcon from "../../assets/folder.svg";
import SearchIcon from "../../assets/search.svg";
import MailingIcon from "../../assets/mailing.svg";
import ChatIcon from "../../assets/chat.svg";
import SummaryIcon from "../../assets/summary.svg";
import RecordIcon from "../../assets/record.svg";
import Divider from "../../assets/divider.svg";
import BellIcon from "../../assets/bell-icon.svg";
import SmallDivider from "../../assets/divider-small.svg";
import SettingIcon from "../../assets/settings.svg";
import ArrowLeft from "../../assets/arrow-left.svg";
import LogoImg from "../../assets/LogoImg.svg";
import UserIcon from "../../assets/user.png";
import DownArrorIcon from "../../assets/down-arrow.svg";
import MoreIcon from "../../assets/More-horizontal.svg";
import ForestIcon from "../../assets/forest.svg";
import TodoIcon from "../../assets/Todo.svg";
import CalendarIcon from "../../assets/calendar.svg";
import AiIcon from "../../assets/ai-icon.svg";
import ExpandIcon from "../../assets/more.svg";

const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  profileUpdated,
  setProfileUpdated,
  notifUpdated,
  setNotifUpdated
  
}) => {
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setUserName] = useState(""); // State to hold user's name
  const [profileImage, setProfileImage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const socketRef = useRef(null);

  useEffect(() => {
    const userId = localStorage.getItem('id');
    if (!userId) return;

    // Create socket connection
    socketRef.current = io("https://api.aiflowpro.io", {
      withCredentials: true,
      transports: ['websocket']
    });

    // Join user's room
    socketRef.current.emit('join', userId);

    // Listen for new notifications
    socketRef.current.on('newNotification', (data) => {
      console.log('New notification received:', data);
      setNotificationCount(data.count);
      // You could also add a toast notification here if desired
    });

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const image = localStorage.getItem("image");
    setProfileImage(image || null);
    const storedName = localStorage.getItem("name");
    if (storedName) {
      setUserName(storedName);
    }

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (profileUpdated) { 
      setProfileImage(localStorage.getItem("image"));
      setUserName(localStorage.getItem("name"));
      setProfileUpdated(false);
    }
  }, [profileUpdated, setProfileUpdated]); 

  useEffect(() => {
    if (notifUpdated) { 
      fetchNotificationCount();
      setNotifUpdated(false);
    }
  }, [notifUpdated]); 

  const fetchNotificationCount = async () => {
    try {
      const userId = localStorage.getItem('id');
      if (!userId) return;

      const response = await fetch(
        `https://api.aiflowpro.io/api/notification/unread-count?userId=${userId}`
      );
      const data = await response.json();
      
      if (data.responseCode === 200) {
        setNotificationCount(data.count);
      }
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  useEffect(() => {
    fetchNotificationCount();
  }, [])

  const handleNavItemClick = (index) => {
    setActiveNavItem(index);
    const routes = [
      "/avatar-chat",
      "/",
      "/email",
      "/chat",
      "/summarize",
      "/generalsearch",
      "/transcriber",
      "/forest",
      "/todo",
      "/skills",
      "/avatar-calendar"
    ];
    if (routes[index]) {
      navigate(routes[index]);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    setShowPopup(false); 
    navigate("/login"); 
  };

  const handleNotificationClick = () => {
    setActiveNavItem(11);
    navigate("/notifications"); 
  };

  const handleUpdateProfile = () => {
    setShowPopup(false);
    navigate("/updateprofile");
  };

  const handleBilling = () => {
    setShowPopup(false); 
    navigate("/billing"); 
  };

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const navItems = [
    { icon: AiIcon, text: "AI Assistant" },
    { icon: FolderIcon, text: "Applications" },
    { icon: MailingIcon, text: "E-mail automations" },
    { icon: ChatIcon, text: "Chat with doc" },
    { icon: SummaryIcon, text: "Summarize" },
    { icon: SearchIcon, text: "General search" },
    { icon: RecordIcon, text: "Meetings" },
    { icon: ForestIcon, text: "Forest App" },
    { icon: TodoIcon, text: "Todo App" },
    { icon: RecordIcon, text: "Skills" },
    { icon: CalendarIcon, text: "Assistant Calendar" },
  ];

  // Set of indices that have active routes
  const navItemsWithRoutes = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const alwaysVisibleIndexes = [0, 10, 1, 9];
  const expandableIndexes = navItems.reduce((acc, _, index) => {
    if (!alwaysVisibleIndexes.includes(index)) {
      acc.push(index);
    }
    return acc;
  }, []);

  return (
    <SidebarContainer isOpen={isSidebarOpen}>
      <Header>
        <LogoIcon src={isSidebarOpen ? Logo : LogoImg} />
        {isSidebarOpen && <Caption>Start using your AI apps</Caption>}
      </Header>
      <Nav>
        {navItems.map((item, index) => (
          <NavItem
            isOpen={isSidebarOpen}
            key={index}
            isActive={activeNavItem === index}
            onClick={() => handleNavItemClick(index)}
            hasRoute={navItemsWithRoutes.has(index)}
            className={`nav-item-${index}`}
            style={{ display: alwaysVisibleIndexes.includes(index) || (isExpanded && expandableIndexes.includes(index)) ? 'flex' : 'none',}}
          >
            <ItemImg src={item.icon} isOpen={isSidebarOpen} />
            {isSidebarOpen && <ItemText>{item.text}</ItemText>}
            {index === 1 && isSidebarOpen && (
              <ExpandButton onClick={toggleExpand} isExpanded={isExpanded}>
                <img src={ExpandIcon} alt="expand" />
              </ExpandButton>
            )}
          </NavItem>
        ))}
      </Nav>
      <DividerDiv src={isSidebarOpen ? Divider : SmallDivider} alt="divider" />
      <TeamStorage>
        {isSidebarOpen && <Caption>Team Storage</Caption>}
        {isSidebarOpen && ( <ComingSoon className={`nav-item-settings`} isOpen={isSidebarOpen} style={{ marginTop: "1rem" }}>Coming Soon...</ComingSoon>)}
      </TeamStorage>
      <div style={{ marginTop: "auto", display: "flex", flexDirection: "column", width: "70%"}}>
        <Nav style={{width: "100%"}}>
          <NavItem isOpen={isSidebarOpen} isActive={activeNavItem === 11} onClick={() => handleNotificationClick(10)} hasRoute={true} >
            <ItemImg src={BellIcon} isOpen={isSidebarOpen} />
            {isSidebarOpen && <ItemText>Notification</ItemText>}
            {notificationCount > 0 && <Counter isOpen={isSidebarOpen}>{notificationCount}</Counter>}
          </NavItem>
        </Nav>
        <DividerDiv src={isSidebarOpen ? Divider : SmallDivider} alt="divider" />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}} >
          <UserDiv>
            <img src={profileImage || UserIcon} alt="avatar" style={{ borderRadius: "50%", width: "2rem", height: "2rem" }} />
            <UserText isOpen={isSidebarOpen}>{userName}</UserText>
            <More isOpen={isSidebarOpen} src={DownArrorIcon} alt="arrow" style={{ transform: "rotate(180deg)" }} onClick={() => setShowPopup(!showPopup)}/>
            <Popup isOpen={showPopup} ref={popupRef}>
              <PopupItem onClick={handleUpdateProfile}>Profile</PopupItem>
              <PopupItem onClick={handleBilling}>Billing</PopupItem>
              <PopupItem onClick={handleLogout}>Logout</PopupItem>
            </Popup>
          </UserDiv>
        </div>
      </div>
      <ToggleButton onClick={toggleSidebar}>
        <img src={ArrowLeft} alt="toggle" style={{ transform: isSidebarOpen ? "rotate(0deg)" : "rotate(180deg)" }}/>
      </ToggleButton>
    </SidebarContainer>
  );
};

export default Sidebar;
