import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Container, Content, Header, Heading } from "./Style";
import { Flex, Button, Typography, message as antMessage } from "antd";
import {
  HistoryOutlined,
  PlusOutlined,
  CopyOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import ChatInput from "./ChatInput";
import { ChatDiv, ChatBox, ChatText, ScrollableChat } from "../Summarize/Style";
import styled from "styled-components";
import BackIcon from "../../assets/back-icon.svg";
import Star from "../../assets/star.svg";
import axios from "axios";
import s3 from "../Environment/Asconfig";
import ReactMarkdown from "react-markdown";
import { MessageContainer } from "../Avatar/style";

// Move styled components to top level
const ChatContainer = styled.div`
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding: 20px;
`;

const MessageActions = styled.div`
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  gap: 10px;
  padding: 4px;
  background-color: white;
`;

const ErrorMessage = styled(ChatDiv)`
  color: #ff4d4f;
  background: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 8px 12px;
  border-radius: 8px;
`;

const ChatBoxWrapper = styled(ChatBox)`
  position: relative;
  &:hover ${MessageActions} {
    display: flex;
  }
`;

const LoadingMessage = styled(ChatBoxWrapper)`
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  animation: pulse 1.5s infinite;
  background: #f5f5f5;
`;
const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 120px;
  scroll-behavior: smooth;
`;

const InputWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: white;
`;

const UserMessageWrapper = styled(ChatBoxWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(49, 49, 51, 0.06);
  background: rgba(255, 255, 255, 1);
  max-width: 50%;
`;

const AssistantMessageWrapper = styled(ChatBoxWrapper)`
  max-width: 50%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(49, 49, 51, 0.06);
  background: linear-gradient(
    180deg,
    rgba(49, 49, 51, 0) 0%,
    rgba(49, 49, 51, 0.12) 100%
  );

  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;

const UserAvatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
`;

export default function RunSkills() {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [chatId, setChatId] = useState(null);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [skillData, setSkillData] = useState(null);

  const username = localStorage.getItem("name");

  // Add ref for auto-scrolling
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const initializeChat = async () => {
      try {
        const response = await axios.get(
          `https://api.aiflowpro.io/api/skill/chat/${id}`
        );
        setChatId(response.data.data._id);

        setMessages(response.data.data.messages);
      } catch (error) {
        // Create new chat when none exists (404 error)
        const newChat = await axios.post(
          "https://api.aiflowpro.io/api/skill/chat/",
          { skillId: id }
        );
        setChatId(newChat.data.data._id);
      }
    };

    initializeChat();

    const fetchSkillData = async () => {
      try {
        const response = await axios.get(
          `https://api.aiflowpro.io/api/skill/${id}`
        );
        setSkillData(response.data.data);
      } catch (error) {
        console.error("Error fetching skill data", error);
      }
    };

    fetchSkillData();
  }, [id]);
  const saveMessage = async (role, content) => {
    try {
      console.log(chatId);
      await axios.post("https://api.aiflowpro.io/api/skill/chat/message", {
        chatId,
        role,
        message: content,
      });
    } catch (error) {
      console.error("Error saving message:", error);
    }
  };

  const uploadFileToS3 = async (file) => {
    const selectedUserId = localStorage.getItem("id");
    const folderName = `${selectedUserId}/documents`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "aiflowpro-fe-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const handleRunSkill = async (query) => {
    try {
      setIsLoading(true);

      // Upload files if present
      let uploadedUrls = [];
      if (file) {
        const fileUrl = await uploadFileToS3(file);
        uploadedUrls.push(fileUrl);
      }

      // Save user message
      await saveMessage("user", query);
      setMessages((prev) => [...prev, { role: "user", message: query }]);

      const payload = {
        user_id: localStorage.getItem("id"),
        skill_id: id,
        query: query,
        user_name: username,
        objectives: skillData.objectives,
        task_guidelines: skillData.taskGuidelines,
        required_output: skillData.requiredOutput,
        messages: messages,
        uploaded_urls: uploadedUrls,
        other_rules: skillData.otherRules,
      };

      const response = await axios.post(
        "https://flowproai.dialect-ai.com/ai_flow_pro/run_skill",
        payload
      );

      // Save assistant message
      await saveMessage("assistant", response.data.content);
      setMessages((prev) => [
        ...prev,
        { role: "assistant", message: response.data.content },
      ]);

      // Reset file state
      setFile(null);
    } catch (error) {
      console.error("Error running skill:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
    antMessage.success("Copied to clipboard");
  };

  const handleFeedback = (messageId) => {
    // Implement feedback functionality
  };

  const handleFileChange = (info) => {
    setFile(info.file);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const ChatMessage = ({ message }) => (
    <ChatDiv>
      {message.role === "user" ? (
        <UserMessageWrapper>
          <UserAvatar
            src={localStorage.getItem("image") || "default-avatar.png"}
            alt="User"
          />
          <ChatText>{message.message}</ChatText>
        </UserMessageWrapper>
      ) : (
        <AssistantMessageWrapper>
          <ChatText>
            <ReactMarkdown>{message.message}</ReactMarkdown>
          </ChatText>
          <MessageActions>
            <Button
              icon={<CopyOutlined />}
              type="text"
              onClick={() => handleCopy(message.message)}
            />
            <Button
              icon={<MessageOutlined />}
              type="text"
              onClick={() => handleFeedback(message._id)}
            />
          </MessageActions>
        </AssistantMessageWrapper>
      )}
    </ChatDiv>
  );

  return (
    <Container isOpen={isSidebarOpen}>
      <Flex justify="space-between" align="center">
        <Flex gap={15} align="center">
          <Header onClick={() => navigate("/skills")}>
            <img src={BackIcon} alt="back" />
            <Heading>Run skill</Heading>
          </Header>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/add-skills")}
            style={{ marginTop: "1.8rem" }}
          >
            Start new Chat
          </Button>
        </Flex>
        <Button
          type="default"
          icon={<HistoryOutlined />}
          onClick={() => navigate("/add-skills")}
          style={{ marginTop: "1.8rem", background: "#F1F1F5" }}
        >
          History
        </Button>
      </Flex>
      <Content>
        <div style={{ marginLeft: "auto", marginRight: "auto", width: "70%" }}>
          <ChatContainer>
            {messages.length <= 1 ? (
              <Flex justify="center" align="center" style={{ height: "100%" }}>
                <Flex vertical align="center" gap={50} style={{ width: "50%" }}>
                  <Flex
                    gap={10}
                    justify="center"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <img
                      src={Star}
                      alt="logo"
                      width={32}
                      height={32}
                      style={{ marginTop: "0.5rem" }}
                    />
                    <Typography.Title level={1} color="#313133">
                      Good afternoon {username}
                    </Typography.Title>
                  </Flex>
                </Flex>
              </Flex>
            ) : null}
            <MessagesContainer>
              {messages.map((msg, index) => (
                <ChatMessage key={index} message={msg} />
              ))}
              {isLoading && (
                <LoadingMessage>
                  <ChatText>🤖 Assistant is thinking...</ChatText>
                </LoadingMessage>
              )}

              <div ref={messagesEndRef} />
            </MessagesContainer>
            <InputWrapper>
              <ChatInput
                message={message}
                setMessage={setMessage}
                handleSend={() => {
                  if (message.trim()) {
                    handleRunSkill(message);
                    setMessage("");
                  }
                }}
                file={file}
                handleFileChange={handleFileChange}
                handleRemoveFile={handleRemoveFile}
              />
            </InputWrapper>
          </ChatContainer>
        </div>
      </Content>
    </Container>
  );
}
