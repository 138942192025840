import React, { useState } from "react";
import {
  ModalOverlay,
  UploadContent,
  UploadTxt,
  ContentDiv,
  TaskTitle,
  TaskDescription
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import DeleteIcon from "../../assets/delete-grey.svg";
import { CircularProgress } from "@mui/material";

const TaskModal = ({ closeAddTaskModal, fetchEvents }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  return (
    <ModalOverlay>
      <UploadContent>
        <div style={{ display: "flex", marginLeft: "auto", gap: "0.5rem" }}>
          <img
            src={CloseX}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeAddTaskModal}
          />
        </div>
        <ContentDiv>
          <TaskTitle  as="input" type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
          <TaskDescription as="textarea" placeholder="Task Details" value={description} onChange={(e) => setDescription(e.target.value)} style={{minHeight: "8.125rem"}}/>
        </ContentDiv>
      </UploadContent>
    </ModalOverlay>
  );
};

export default TaskModal;
