import React from "react";
import { Flex, Button, Upload, Typography } from "antd";
import {
  ArrowUpOutlined,
  FileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Attachment from "../../assets/paperclip.svg";

const ChatInput = ({
  message,
  setMessage,
  handleSend,
  file,
  handleFileChange,
  FileError,
  handleRemoveFile,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <Flex
      style={{
        width: "95%",
        border: "1px solid #E0E0E0",
        borderRadius: "16px",
        padding: "8px 16px",
        boxShadow:
          "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Flex>
        <Upload
          beforeUpload={() => false}
          onChange={handleFileChange}
          showUploadList={false}
        >
          <Button
            icon={
              <img
                src={Attachment}
                alt="attachment"
                style={{ width: "16px", height: "16px" }}
              />
            }
            style={{ backgroundColor: "transparent", border: "none" }}
          />
        </Upload>
        <TextArea
          placeholder="Create a leave email"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          bordered={false}
          style={{
            flex: 1,
            fontSize: "16px",
            height: "132px",
            resize: "none",
          }}
        />
        <Button
          icon={<ArrowUpOutlined />}
          type="primary"
          shape="circle"
          onClick={handleSend}
          style={{ backgroundColor: "#3F8CFF", border: "none" }}
        />
      </Flex>
      {file && (
        <Flex
          align="center"
          style={{
            marginTop: "8px",
            padding: "8px",
            borderRadius: "8px",
            backgroundColor: "#F5F5F5",
          }}
        >
          <FileOutlined style={{ fontSize: "18px", color: "#595959" }} />
          <Typography.Text style={{ marginLeft: "8px", flex: 1 }}>
            {file.name}
          </Typography.Text>
          <Button
            icon={<CloseOutlined />}
            type="text"
            onClick={handleRemoveFile}
            style={{ color: "#FF4D4F" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ChatInput;
