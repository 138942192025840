import React from 'react';
import CommentIcon from "../../assets/comment-icon.svg";

const CustomEvent = ({ event }) => {
  const handleCommentClick = (e) => {
    e.stopPropagation(); 
    console.log("Comments:", event.comments);
  };

  return (
    <div style={{ display: "flex" }}>
      <span>
        {event.title.length > 15
          ? `${event.title.substring(0, 15)}...`
          : event.title}
      </span>
      {/* {event.comments !== "Holiday" && (
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "0.3rem" }}>
          <img
            src={CommentIcon}
            alt="Comment"
            style={{ marginTop: "0.15rem" }}
            onClick={handleCommentClick}
          />
          <span>{event.comments}</span>
        </div>
      )} */}
    </div>
  );
};

export default CustomEvent;