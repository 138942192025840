import React, { useState } from "react";
import {
  ModalOverlay,
  UploadContent,
  UploadTxt,
  ContentDiv,
  TaskTitle,
  TaskDescription
} from "./Style";
import CloseX from "../../assets/x-close.svg";
import DeleteIcon from "../../assets/delete-grey.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const TaskModal = ({ closeModal, event, fetchEvents }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("https://api.aiflowpro.io/api/task/deleteTask", {
        taskId: event.id,
      });
      if (response.status === 200 && response.data.responseCode === 200) {
        console.log("Task deleted successfully");
        closeModal();
        fetchEvents();
      } else {
        console.error("Failed to delete task", response.data);
      }
    } catch (error) {
      console.error("Error deleting task", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay>
      <UploadContent>
        <div style={{ display: "flex", marginLeft: "auto", gap: "0.5rem" }}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <img
              src={DeleteIcon}
              alt="delete"
              style={{ cursor: "pointer" }}
              onClick={handleDelete}
            />
          )}
          <img
            src={CloseX}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          />
        </div>
        <ContentDiv>
          <TaskTitle>{event.title}</TaskTitle>
          <TaskDescription>{event.description}</TaskDescription>
        </ContentDiv>
      </UploadContent>
    </ModalOverlay>
  );
};

export default TaskModal;
