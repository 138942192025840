import React, { useState, useRef, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Container, Header, Heading, Content } from "../Summarize/Style";
import { useOutletContext, useNavigate } from "react-router-dom";
import ChatMessage from "./ChatMessage";
import SuggestionMessages from './SuggestionMessages';
import { AvatarSection, AssistantName, AssistantTitle, ChatBox, ChatDiv, InputContainer, Input, FileDiv, EditImg, UploadDiv } from "./style";
import { UploadBtn } from "../Summarize/Style";
import BackIcon from "../../assets/back-icon.svg";
import ArrowUpIcon from "../../assets/arrow-up-white.svg";
import PaperClip from "../../assets/paper-clip.svg";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";
import s3 from "../Environment/Asconfig";
import EditIcon from "../../assets/edit-avatar.svg";
import { AVATARS } from "../Constants/Constants";
import Delete from "../../assets/delete-grey.svg";
import ComputerIcon from "../../assets/computer-icon.svg";
import AvatarDriveFilePicker from "./AvatarDriveFilePicker";

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

const formatTimestamp = (date) => {
  const offset = -date.getTimezoneOffset();
  const offsetSign = offset >= 0 ? "+" : "-";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, "0");
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");

  return `${date.toISOString().slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

const getFileTypeFromName = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (extension === 'pdf') return 'pdf';
  if (['doc', 'docx'].includes(extension)) return 'DOCX';
  if (extension === 'txt') return 'TXT';
  return 'unknown';
};

const formatFileFromUrl = (url) => {
  const fileName = decodeURIComponent(url.split('/').pop());
  return {
    name: fileName,
    type: getFileTypeFromName(fileName),
    url: url 
  };
};

const fetchAvatarData = async () => {
  const userId = localStorage.getItem("id");
  const response = await fetch(`https://api.aiflowpro.io/api/auth/get-avatar?user_id=${userId}`);
  const data = await response.json();
  return data;
};

const saveChat = async (userId, query, content, files) => {
  await fetch("https://api.aiflowpro.io/api/auth/avatar-chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: userId,
      query,
      content,
      files,
    }),
  });
};

const checkAssistantOnboarded = async (userId) => {
  const response = await fetch("https://api.aiflowpro.io/api/auth/check-assistant-onboarded", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId }),
  });
  const data = await response.json();
  return data.isAssistantOnboarded;
};

const AvatarChat = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveFiles, setDriveFiles] = useState([]);
  const bottomRef = useRef(null);
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(true);
  const [avatarData, setAvatarData] = useState(null);
  const [clearingChat, setClearingChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  let history = [];
  const fetchSuggestions = async (messageHistory) => {
    const userId = localStorage.getItem("id");
    try {
      // Only proceed with fetching suggestions if assistant is onboarded
      if (!avatarData?.isAssistantOnboarded) {
        setShowSuggestions(false);
        return;
      }

      const payload = {
        user_id: userId,
        ...(messageHistory?.length > 0 && {
          messages: messageHistory.map(msg => ({
            role: msg.isUser ? "human" : "ai",
            content: msg.text
          }))
        })
      };
  
      const response = await fetch("https://assistant-api.aiflowpro.io/suggestions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      });
      
      const data = await response.json();
      setSuggestions(data.suggestions || []);
      setShowSuggestions(true); // Only show if we get to this point (meaning assistant is onboarded)
      
      // Add a small delay to ensure DOM updates before scrolling
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setShowSuggestions(false);
    }
  };

  const fetchChatHistory = async () => {
    const userId = localStorage.getItem("id");
    const response = await fetch(`https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`);
    const data = await response.json();
  
    if (data.responseCode === 200) {
      const formattedMessages = data.avatarChats.flatMap(chat => [
        {
          role: "human",
          content: chat.query,
          files: chat.files.map(fileUrl => formatFileFromUrl(fileUrl))
        },
        {
          role: "ai",
          content: chat.content
        }
      ]);
      // console.log("history", formattedMessages);
      history = formattedMessages;
      const formattedDisplayMessages = formattedMessages.map(msg => ({
        id: msg.role === "human" ? msg.content + "-query" : msg.content + "-response",
        text: msg.content,
        isUser: msg.role === "human",
        files: msg.role === "human" ? msg.files : []
      }));
  
      // Add a "Loading..." message for the greeting
      formattedDisplayMessages.push({
        files: [],
        id: "greeting-loading",
        isUser: false,
        text: "Loading...",
      });
  
      return formattedDisplayMessages;
    }
    return [];
  };
  
  // In the useEffect, modify the chat initialization
  useEffect(() => {
    const initializeChat = async () => {
      try {
        // Avatar Loading
        setIsAvatarLoading(true);
        const avatarResponse = await fetchAvatarData();
        if (avatarResponse.responseCode === 404) {
          navigate("/avatar-selection");
          return;
        }
        if (avatarResponse.responseCode === 200) {
          setAvatarData(avatarResponse.avatar);
          setIsAvatarLoading(false);
        }
  
        // Chat History Loading
        setIsChatHistoryLoading(true);
        const formattedMessages = await fetchChatHistory();
        setMessages(formattedMessages);
        setIsChatHistoryLoading(false);
  
        // Fetch greeting while showing "Loading..." message
        const userId = localStorage.getItem("id");
        const userName = localStorage.getItem("name");
        const response = await fetch("https://assistant-api.aiflowpro.io/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            user_name: userName,
            messages: history,
            timestamp: formatTimestamp(new Date()),
            s3_document_urls: [],
            is_greeting: true,
            greeting_language: avatarResponse.avatar.greetingLanguage,
            ...(avatarResponse.avatar.isAssistantOnboarded === false && {
              is_onboarded: false
            })
          }),
        });     
        const newData = await response.json();
        
        // Save Greeting Chat
        await saveChat(userId, "", newData.content, []);
  
        // Update messages to replace "Loading..." with actual greeting
        setMessages(prevMessages => {
          const updatedMessages = prevMessages.map(msg => 
            msg.id === "greeting-loading" 
              ? {
                  files: [],
                  id: "6748965e0d35f8e8816fd171-greeting",
                  isUser: false,
                  text: newData.content,
                }
              : msg
          );
          
          // Fetch suggestions after updating messages
          fetchSuggestions(updatedMessages);
          
          return updatedMessages;
        });
      } catch (error) {
        console.error("Error initializing chat:", error);
        setIsAvatarLoading(false);
        setIsChatHistoryLoading(false);
      } finally {
        setInitialLoadComplete(true);
      }
    };
  
    initializeChat();
  }, [navigate]);

  // Separate useEffect for handling scrolling
  useEffect(() => {
    if (bottomRef.current && (messages.length > 0 || initialLoadComplete)) {
      bottomRef.current.scrollIntoView({ behavior: initialLoadComplete ? "smooth" : "auto" });
    }
  }, [messages, initialLoadComplete]);

  // Additional useEffect to handle suggestion changes
  useEffect(() => {
    if (showSuggestions && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showSuggestions]);
  
  const handleClearChat = async () => {
    const userId = localStorage.getItem("id");
    const userName = localStorage.getItem("name");
    setClearingChat(true);
    
    try {
      const response = await fetch("https://api.aiflowpro.io/api/auth/delete-avatar-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId
        }),
      });
      
      const data = await response.json();
      
      if (data.responseCode === 200) {
        const responseNew = await fetch("https://assistant-api.aiflowpro.io/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            user_name: userName,
            messages: [],
            timestamp: formatTimestamp(new Date()),
            s3_document_urls: [],
            is_greeting: true,
            greeting_language: avatarData.greetingLanguage
          }),
        });     
        const newData = await responseNew.json();
        // Save Greeting Chat
        await saveChat(userId, "", newData.content, []);
  
        setMessages([{
          files: [],
          id: "6748965e0d35f8e8816fd171-greeting",
          isUser: false,
          text: newData.content,
        }]);
      } else {
        console.error("Failed to clear chat:", data.message);
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
    } finally {
      setClearingChat(false);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    handleSendMessage(suggestion);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const pop_id = open ? 'simple-popover' : undefined;

  const handleDriveFiles = async (fileLinks) => {
    handleClosePopover();
    const apiKey = process.env.REACT_APP_API_KEY;
    
    const newDriveFiles = fileLinks.map(fileId => {
      const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;
      return {
        name: `Drive File ${fileId}`, // This will be updated when metadata is fetched
        type: 'application/octet-stream', // Default type
        isDrive: true,
        driveUrl: fileUrl,
        id: fileId
      };
    });

    try {
      const updatedFiles = await Promise.all(newDriveFiles.map(async (file) => {
        const metadataUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?fields=name,mimeType&key=${apiKey}`;
        const response = await fetch(metadataUrl);
        const metadata = await response.json();
        
        return {
          ...file,
          name: metadata.name,
          type: metadata.mimeType
        };
      }));

      setUploadedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    } catch (error) {
      console.error("Error fetching Drive file metadata:", error);
    }
  };

  const handleFileUpload = (e) => {
    handleClosePopover();
    const files = Array.from(e.target.files).map(file => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false
    }));
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const sendMessage = async (messageContent, files) => {
    const userId = localStorage.getItem("id");
    const userName = localStorage.getItem("name");
     
    const userMessage = {
      role: "human",
      content: messageContent,
      files: files
    };

    // Hide suggestions while processing
    setShowSuggestions(false);

    setMessages((prevMessages) => [
      ...prevMessages,
      { 
        id: prevMessages.length + 1, 
        text: messageContent, 
        isUser: true,
        files: files
      },
    ]);
  
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 2, text: "Loading...", isUser: false },
    ]);

    const fileUrls = await Promise.all(files.map(async (file) => {
      if (file.isDrive) {
        return file.driveUrl;
      } else {
        return uploadFileToS3(file.file);
      }
    }));

        // Check onboarding status if avatar is not onboarded
    let isOnboarded = avatarData?.isAssistantOnboarded;
    if (!isOnboarded) {
      isOnboarded = await checkAssistantOnboarded(userId);
      avatarData.isAssistantOnboarded = isOnboarded;
    }
        
    try {
      const response = await fetch("https://assistant-api.aiflowpro.io/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          user_name: userName,
          messages: [
            ...messages.map((msg) => ({
              role: msg.isUser ? "human" : "ai",
              content: msg.text,
            })),
            userMessage,
          ],
          timestamp: formatTimestamp(new Date()),
          s3_document_urls: fileUrls,
          ...(isOnboarded === false && {
            is_onboarded: false
          })
        }),
      });
      const data = await response.json();
  
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: data.content, isUser: false, ...(data.url ? { generatedFileUrl: data.url } : {}) } : msg
        );
        
        // Fetch new suggestions after message update
        fetchSuggestions(updatedMessages);
        
        return updatedMessages;
      });

      await saveChat(userId, messageContent, data.content, fileUrls);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..." ? { ...msg, text: "Error sending message", isUser: false } : msg
        )
      );
      setShowSuggestions(true); // Show suggestions again in case of error
    }
  };

  const handleSendMessage = async (inputMessage = '') => {
    const message = 
      (typeof inputMessage === "string" ? inputMessage.trim() : "") || 
      (typeof inputValue === "string" ? inputValue.trim() : "");
  
    if (message === "") return;
  
    const currentUploadedFiles = [...uploadedFiles];
    setUploadedFiles([]);
    setInputValue("");
    
    await sendMessage(message, currentUploadedFiles);
  };

  const avatarImage = avatarData ? AVATARS.find(avatar => avatar.imageId === avatarData.imageId)?.src : null;

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const files = Array.from(droppedFiles).map(file => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false
    }));    
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const renderAvatarSection = () => {
    const avatarImage = avatarData ? AVATARS.find(avatar => avatar.imageId === avatarData.imageId)?.src : null;

    return (
      <AvatarSection>
        <img src={avatarImage} alt="Avatar" style={{ width: "2.5rem", height: "2.5rem", borderRadius: "60%", background: "#FFF" }} />
        <AssistantName>{avatarData ? avatarData.avatarName : "Michael"}</AssistantName>
        <EditImg src={EditIcon} alt="edit" onClick={() => navigate("/avatar-selection")}/>
        {/* <AssistantTitle>Your Personal Assistant</AssistantTitle> */}
      </AvatarSection>
    );
  };

  const renderChatContent = () => {
    if (isChatHistoryLoading) {
      return (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%' 
        }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <ChatDiv>
        {messages.map((message, index) => (
          <ChatMessage 
            key={index} 
            text={message.text} 
            isUser={message.isUser} 
            avatarImage={avatarData ? AVATARS.find(avatar => avatar.imageId === avatarData.imageId)?.src : null} 
            files={message.files || []}
            fileUrl={message?.generatedFileUrl}
            userColor="rgba(194, 200, 222, 0.20)" 
            userTextColor="#FFF" 
            avatarColor="#FFF" 
            avatarTextColor="#313133" 
          />
        ))}
        <div ref={bottomRef} />
      </ChatDiv>
    );
  };

  return (
    <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
      <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} onClick={() => navigate("/")}>
          <img src={BackIcon} alt="back" />
          <Heading>Avatar Chat</Heading>
        </div>
        <UploadBtn 
          onClick={handleClearChat}
          disabled={clearingChat}
          style={{ cursor: clearingChat ? 'default' : 'pointer' }}
        >
          {clearingChat ? (
            <CircularProgress size={20} style={{ marginRight: "0.5rem" }} />
          ) : (
            <img src={Delete} alt="Manage Files" style={{height:"1.5rem"}}/>
          )}
          Clear Chat
        </UploadBtn>
      </Header>
      <Content style={{ background: "linear-gradient(180deg, #7EAFFD 0%, #3B71C8 100%)" }}>
        {renderAvatarSection()}
        <ChatBox
          onDrop={handleDrop}
          onDragOver={handleDragOver}                  
        >
          {renderChatContent()}
          {showSuggestions && (
            <SuggestionMessages 
              suggestions={suggestions}
              onSuggestionClick={handleSuggestionClick} 
              isPopup={false}
            />
          )}
          <InputContainer style={{ borderRadius: uploadedFiles.length > 0 ? "1.25rem" : "6.25rem" }}>
            <FileDiv style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}>
              {uploadedFiles.map((file, index) => (
                <FileCard
                  key={index}
                  fileName={file?.name}
                  fileType={file?.type?.includes("pdf") ? "pdf" : file?.type?.includes("word") ? "DOCX" : "TXT"}
                  fileIcon={
                    file?.type?.includes("pdf") ? PdfIcon : file?.type?.includes("word") ? DocxIcon : TxtIcon
                  }
                  onRemove={() => removeFile(file.name)}
                  isPopup={false}
                />
              ))}
            </FileDiv>
            <div style={{ display: "flex", width: "100%", gap: "0.25rem"}}>
              <input
                type="file"
                multiple
                accept=".pdf,.doc,.docx,.txt"
                style={{ display: "none" }}
                id="fileInput"
                onChange={handleFileUpload}
              />
                <img src={PaperClip} alt="attach file" onClick={handleOpenPopover} style={{cursor: "pointer"}}/>
                <Popover
                    id={pop_id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: "#f1f1f5"
                  }}>
                    <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                      <UploadDiv>
                        <img src={ComputerIcon} alt="computer" /> Upload from computer
                      </UploadDiv>
                    </label>
                    <AvatarDriveFilePicker onFilesSelected={handleDriveFiles} />
                  </div>
                  </Popover>
                  <Input 
                    type="text" 
                    placeholder="Type Message" 
                    value={inputValue} 
                    onChange={(e) => setInputValue(e.target.value)} 
                    onKeyPress={(e) => { if (e.key === "Enter") { handleSendMessage(); }}} 
                  />
                  <img 
                    src={ArrowUpIcon} 
                    alt="send message" 
                    onClick={handleSendMessage} 
                    style={{ marginLeft: "auto", cursor: "pointer" }} 
                  />
                </div>
              </InputContainer>
            </ChatBox>
          </Content>
        </Container>
      );
    };
    
    export default AvatarChat;