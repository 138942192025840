import React, { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import Drive from "../../assets/Gdrive.png";
import { DriveUploadBtn } from "./Style";

function SummarizeDriveFilePicker({ onFilesSelected }) {
  const [openPicker, authResponse] = useDrivePicker();
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false); // Flag to prevent multiple refresh calls

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    const storedRefreshToken = localStorage.getItem("refresh_token");
    const storedExpiresIn = localStorage.getItem("expires_in");

    if (storedAccessToken && storedRefreshToken) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
      setExpiresIn(storedExpiresIn);
    }
  }, []);

  const fetchTokens = async (authCode) => {
    try {
      const response = await fetch("https://oauth2.googleapis.com/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          code: authCode,
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_SUMMARIZE_REDIRECT_URI,
          grant_type: "authorization_code",
        }),
      });

      const data = await response.json();
      if (data.error) {
        console.error("Error fetching tokens:", data.error);
        return; // Handle errors gracefully
      }

      const { access_token, refresh_token, expires_in } = data;

      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setExpiresIn(expires_in);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("expires_in", expires_in);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }
  };

  const refreshAccessToken = async () => {
    if (isRefreshing || !refreshToken) return; // Prevent multiple refresh calls

    setIsRefreshing(true); // Set the flag to indicate a refresh is in progress
    try {
      const response = await fetch("https://oauth2.googleapis.com/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refreshToken,
          grant_type: "refresh_token",
        }),
      });

      const data = await response.json();
      if (data.error) {
        console.error("Error refreshing access token:", data.error);
        return; // Handle errors gracefully
      }

      const { access_token, expires_in } = data;

      setAccessToken(access_token);
      setExpiresIn(expires_in);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("expires_in", expires_in);

      console.log("Access token refreshed:", data);
    } catch (error) {
      console.error("Error refreshing access token:", error);
    } finally {
      setIsRefreshing(false); // Reset the flag after the refresh is done
    }
  };

  const handleOpenPicker = () => {
    if (!accessToken || !refreshToken) {
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SUMMARIZE_REDIRECT_URI}&scope=https://www.googleapis.com/auth/drive.file&access_type=offline&prompt=consent`;
      window.location.href = authUrl;
    } else {
      openPicker({
        clientId: process.env.REACT_APP_CLIENT_ID,
        developerKey: process.env.REACT_APP_DEVELOPER_KEY,
        viewId: "PDFS",
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        token: accessToken,
        callbackFunction: (data) => {
          if (data.action === "picked") {
            const fileLinks = data.docs.map((doc) => doc.id);
            onFilesSelected(fileLinks);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (expiresIn) {
      console.log("expires in:", expiresIn);
      refreshAccessToken();
    }
  }, [expiresIn]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");

    if (authCode && !accessToken) {
      fetchTokens(authCode);
    }
  }, [accessToken]);

  return (
    <DriveUploadBtn onClick={handleOpenPicker}>
      <img src={Drive} alt="Google Drive" height={16} width={16} />
      <span>Open Google Drive</span>
    </DriveUploadBtn>
  );
}

export default SummarizeDriveFilePicker;
