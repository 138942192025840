import React, { useState } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { MainDiv, Iframe } from '../Iframe/Style';
import { Container, Header, Heading, Content } from '../Summarize/Style';
import BackIcon from "../../assets/back-icon.svg";

const Transcriber = () => {
  const { isSidebarOpen } = useOutletContext();
  const email = localStorage.getItem("email");
  const [iframeSrc, setIframeSrc] = useState(
    `https://transcriber.aiflowpro.io/?email=${email}`
  );
  const navigate = useNavigate();

  return (
    // <MainDiv isOpen={isSidebarOpen} style={{padding: "1rem"}}>
    //     <Iframe src={iframeSrc} />
    // </MainDiv>
    <Container isOpen={isSidebarOpen} style={{marginRight: "1.4rem"}}>
        <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }} onClick={() => navigate("/")}>
            <img src={BackIcon} alt="back" />
            <Heading>Transcriber</Heading>
          </div>
        </Header>
        <Content style={{border: "0px"}}>
          <Iframe src={iframeSrc} allow="microphone; clipboard-write" />
        </Content>
    </Container>
  )
}

export default Transcriber
