import React, { useEffect, useState } from "react";
import { message } from "antd";

function AvatarDriveFileUploader({ file, fileName, onUploadComplete }) {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    const storedRefreshToken = localStorage.getItem("refresh_token");
    const storedExpiresIn = localStorage.getItem("expires_in");

    if (storedAccessToken && storedRefreshToken) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
      setExpiresIn(storedExpiresIn);
      // Trigger file upload once we have the tokens
      handleFileUpload(storedAccessToken);
    }
  }, [file]); // Add file as dependency to react to new files

  const refreshAccessToken = async () => {
    if (isRefreshing || !refreshToken) return;
    setIsRefreshing(true);
    try {
      const response = await fetch("https://oauth2.googleapis.com/token", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refreshToken,
          grant_type: "refresh_token",
        }),
      });

      const data = await response.json();
      if (!data.error) {
        const { access_token, expires_in } = data;
        setAccessToken(access_token);
        setExpiresIn(expires_in);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("expires_in", expires_in);
        // Retry file upload with new token
        handleFileUpload(access_token);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleFileUpload = async (token) => {
    if (!file || !token) return;

    try {
      let fileToUpload = file;
      const metadata = {
        name: fileName || file.name,
        mimeType: file.type || "application/pdf",
      };

      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );
      form.append("file", fileToUpload);

      const response = await fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: form,
        }
      );

      const data = await response.json();
      if (!data.error) {
        onUploadComplete && onUploadComplete(data);
        message.success("File uploaded to Drive");
      } else {
        if (data.error.status === "UNAUTHENTICATED") {
          refreshAccessToken();
        } else {
          message.error("Failed to upload file");
        }
      }
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Failed to upload file");
    }
  };

  return null;
}

export default AvatarDriveFileUploader;
