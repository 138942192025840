import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  Upload,
  Form,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import axios from "axios";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import BackIcon from "../../assets/back-icon.svg";
import { Container, Content, Header, Heading } from "./Style";
import s3 from "../Environment/Asconfig";

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

export default function EditSkills() {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const { id } = useParams(); // Fetch skill ID from URL
  const [loading, setLoading] = useState(false);
  const [initialSkill, setInitialSkill] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]); // Track newly selected files
  const [existingFiles, setExistingFiles] = useState([]); // Track files that were already uploaded
  const [filesToDelete, setFilesToDelete] = useState([]); // Track files marked for deletion
  const [form] = Form.useForm();

  useEffect(() => {
    // Fetch skill data when the component mounts
    const fetchSkillData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.aiflowpro.io/api/skill/${id}`
        );
        const skillData = response.data.data;
        setInitialSkill(skillData);
        form.setFieldsValue(skillData);
        setExistingFiles(skillData.files || []); // Set existing files from the server
      } catch (error) {
        console.error("Error fetching skill data", error);
        message.error("Failed to fetch skill data");
      } finally {
        setLoading(false);
      }
    };

    fetchSkillData();
  }, [id, form]);

  const handleFileChange = ({ fileList }) => {
    // Update state only with new files to avoid duplicates
    const uniqueFiles = fileList
      .map((file) => file.originFileObj)
      .filter((file) => !selectedFiles.some((f) => f.name === file.name));

    setSelectedFiles((prev) => [...prev, ...uniqueFiles]);
  };

  const handleFileRemove = (file) => {
    // Mark file for deletion
    setFilesToDelete((prev) => [...prev, file]);
    // Remove file from existingFiles list
    setExistingFiles((prev) =>
      prev.filter((f) => f.fileName !== file.fileName)
    );
  };

  const handleFinish = async (values) => {
    setLoading(true);

    const uploadedFileUrls = [];
    const userId = localStorage.getItem("id");

    // Upload newly selected files to S3
    for (const file of selectedFiles) {
      try {
        const fileUrl = await uploadFileToS3(file);
        uploadedFileUrls.push({
          fileUrl,
          fileName: file.name,
          fileSize: file.size,
          uploadTime: new Date().toISOString(),
        });
      } catch (error) {
        console.error("Error uploading file", error);
        message.error("Failed to upload file: " + file.name);
      }
    }

    // Prepare the payload to send to the server
    const payload = {
      ...values,
      user: userId,
      files: [...existingFiles, ...uploadedFileUrls], // Merge new and existing files
    };

    try {
      // Send update request with new data
      await axios.put(`https://api.aiflowpro.io/api/skill/${id}`, payload);
      message.success("Skill updated successfully!");

      // Delete marked files from S3 if they were removed from the UI
      for (const file of filesToDelete) {
        const deleteParams = {
          Bucket: "aiflowpro-fe-userdata",
          Key: `${userId}/documents/${file.fileName}`,
        };
        await s3.deleteObject(deleteParams).promise();
      }

      navigate("/skills"); // Redirect after successful update
    } catch (error) {
      console.error("Failed to update skill", error);
      message.error("Failed to update skill");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/skills")}>
        <img src={BackIcon} alt="back" />
        <Heading>Edit Skill</Heading>
      </Header>
      <Content>
        <Spin spinning={loading}>
          <Form
            form={form}
            style={{ padding: "36px 36px" }}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={initialSkill}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontWeight: 600 }}>Name</span>}
                  name="name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                >
                  <Input placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontWeight: 600 }}>Model</span>}
                  name="model"
                  rules={[{ required: true, message: "Please select a model" }]}
                >
                  <Select placeholder="Select Model">
                    <Option value="model1">Chat GPT 3.5</Option>
                    <Option value="model2">Chat GPT 4.0</Option>
                    <Option value="model3">Chat GPT 4.0 Mini</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ fontWeight: 600 }}>Objectives</span>}
                  name="objectives"
                  rules={[
                    { required: true, message: "Please enter objectives" },
                  ]}
                >
                  <TextArea placeholder="Description" rows={4} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: 600 }}>Task Guidelines</span>
                  }
                  name="taskGuidelines"
                >
                  <TextArea placeholder="Description" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: 600 }}>Required Output</span>
                  }
                  name="requiredOutput"
                  rules={[
                    { required: true, message: "Please enter required output" },
                  ]}
                >
                  <TextArea placeholder="Description" rows={4} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: 600 }}>
                      Other Rules and Steps
                    </span>
                  }
                  name="otherRules"
                >
                  <TextArea placeholder="Description" rows={4} />
                </Form.Item>
              </Col>
            </Row>

            {/* Add new Query text area */}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={<span style={{ fontWeight: 600 }}>Query</span>}
                  name="query"
                >
                  <TextArea placeholder="Enter your query" rows={4} />
                </Form.Item>
              </Col>
            </Row>

            {/* Existing Files Display */}
            {existingFiles.length > 0 && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: 600 }}>Existing Files</span>
                    }
                  >
                    {existingFiles.map((file) => (
                      <div key={file.fileName}>
                        <span>{file.fileName}</span>
                        <Button
                          onClick={() => handleFileRemove(file)}
                          type="link"
                          danger
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* File Upload */}
            <Form.Item
              label={<span style={{ fontWeight: 600 }}>Upload New Files</span>}
            >
              <Dragger
                name="files"
                multiple
                accept=".pdf"
                beforeUpload={() => false}
                onChange={handleFileChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Drag and drop files, or <a>Browse</a>
                </p>
                <p className="ant-upload-hint">Support PDF files</p>
              </Dragger>
            </Form.Item>

            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {loading ? "Updating..." : "Update"}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Content>
    </Container>
  );
}
